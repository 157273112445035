import axios from "axios";

const href = window.location.href;
let baseUrlAction = null;
if (href.includes("808") || href.includes("localhost")) {
    //console.log("URL bevat '808' of 'localhost'. URL is: " + href);
    baseUrlAction = "http://localhost/stagion/beer/api_beer.php?action=";
} else {
    baseUrlAction = "api/index.php?action=";
}
//console.log("API's baseUrlAction = " + baseUrlAction);

export const getUsers = async () => {
    try {
        const response = await axios.get(baseUrlAction + 'getUsers');
        console.log("response (met config en header info) FROM user.js = ", response);
        return response.data;
        // hier moet hij complete response.data teruggeven!!, want je wilt ook de succes en de sMessage in de view afhandelen
        // hier kent hij this.$root bijvoorbeeld, dus geen emit.snackBar, want deze file zit niet in de scope
    } catch (err) {
        console.error(err);
    }
};

export const getUser = async (inaam, pw) => {
    //console.log("inaam =" + inaam, "pw =" + pw)
    const url = baseUrlAction + 'getUser';
    const payload = { inaam: inaam, pw: pw }; // volgorde velden maakt niet meer uit door aanpassing in API
    const response = await axios.post(url, payload);
    //console.log("response from post getBeers request from bier.js", response);
    return response
}
