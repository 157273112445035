<template>
	<v-app>

        <Menu/>

		<v-main >
            <user-info v-if="$store.state.module_login.info_login"/>
			<router-view 
                class = "normalMargin" 
                v-bind:class="{ leftMargin: $store.state.module_login.info_login }" />
		</v-main>

		<snack-bar></snack-bar>
	</v-app>
</template>

<script>
import Menu from "@/components/layout/Menu.vue";
import SnackBar from "@/components/drawers_dialogs_bars/SnackBar";
import UserInfo from "@/components/drawers_dialogs_bars/UserInfo";

export default {
	name: "App",
	components: {
		SnackBar,
        UserInfo,
        Menu,
	},

	data: () => ({
		dialog: true,       
	}),

    created: function () {
		// haal (bij refressh van de pagina) info_login op uit sessionStorage indien aanwezig
        let info_login = JSON.parse(sessionStorage.getItem("info_login"))
        //console.log("Vanuit Inlog.vue created: local info_login uit sessionStorage = ", info_login);
        if(info_login) {
            this.$store.commit("save_login", info_login);
        }	
	},
};
</script>

<style>
    .normalMargin {
        margin: 20px
    }
    .leftMargin {
        margin: 20px 20px 20px 80px;
    }
</style>
