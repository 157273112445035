// Als je ook apart de actions, state e.a. in aparte files wil zetten
// import actions from './actions';
// import getters from './getters';
// import mutations from './mutations';
// import state from './state';
import axios from "axios";

export default {
    state: {
        info_login: null,
        headerBearer: null,
        //oSesStorage: null,
    },
    mutations: {
        save_login(state, data) {
            state.info_login = data;
            //console.log("vanuit de store save_login(): data=", data);
            sessionStorage.setItem("info_login", JSON.stringify(state.info_login));
            //console.log("info_login van sessionStorage = ", JSON.parse(sessionStorage.getItem("info_login")));
            state.info_login.headerBearer = state.info_login.id + ',' + state.info_login.token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${state.info_login.headerBearer}`;
            
        },
        logout(state) {
            sessionStorage.removeItem("info_login");
            state.info_login = null;
            //console.log("in logout() in store: sessionStorage en  state.info_login zijn gewist")
            //state.info_login.headerBearer = null;
        },
    
    },
    actions: {
    },
    getters: {
    }
}