import Vue from 'vue'
import VueRouter from 'vue-router'

// import Home: normaal inladen. Anders: met import in route zelf voor route level code-splitting for lazy-loading
import Home from '../views/Home.vue' 

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    // {
    //     path: '/beer',
    //     name: 'Beer',
    //     component: () => import('../views/tests/Bier.vue'),
    // },

/* --------start --------- pagina's onder views/tests ----------------------------*/
    {
        path: '/Dragdrop',
        name: 'Dragdrop',
        component: () => import('../views/tests/Dragdrop.vue'),
    },
    {
        path: '/Dragdrop2',
        name: 'Dragdrop2',
        component: () => import('../views/tests/Dragdrop2.vue'),
    },
    {
        path: '/About',
        name: 'About',
        component: () => import('../views/tests/About.vue'),
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import('../views/tests/Users.vue'),
    },
    {
        path: '/beer_base',
        name: 'Beer_base',
        component: () => import('../views/beer/Beer.vue'),
    },
    {
        path: '/beer',
        name: 'Beer',
        component: () => import('../views/beer/Bier.vue'),
    },
    {
        path: '/Adm',
        name: 'Adm',
        component: () => import('../views/tests/Adm.vue'),
    },
/* --------stop ---------- pagina's onder views/tests ----------------------------*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
