<template >
	<v-app-bar app color="primary" id="mainMenu">
		<img
            alt="CSbeer logo"
            src="@/assets/logos/cs_beer.png"
            style="height: 50px"
        />
	
		<v-menu transition="slide-x-transition" bottom right v-for="(item, i) in items" :key="i" >
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					class="mr-2 ml-4 primary"
					v-bind="attrs"
					v-on="on"
                    :to="item.to"
                >
                    <v-icon>{{item.icon}}</v-icon>
                    
					{{item.title}}
                    <v-icon v-if="item.sub_items" class="ml-2">mdi-arrow-down</v-icon>
				</v-btn>
			</template>

			<v-list v-if="item.sub_items">
				<v-list-item v-for="(sub_item, j) in item.sub_items" :key="j" :to="sub_item.to">
                    <v-list-item-title>
                        <v-icon>{{sub_item.icon}}</v-icon>
                        {{sub_item.title}}
                    </v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

        <v-spacer></v-spacer>

        <v-tooltip left v-if="$store.state.module_login.info_login">
            <template v-slot:activator="{ on, attrs }">
                <v-btn 
                    class="mr-2 primary" 
                    @click="handleLogout()"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="mr-2">mdi-account-arrow-left-outline</v-icon>    
                </v-btn>
            </template>
            <span>{{$store.state.module_login.info_login.naam}} uitloggen</span>
        </v-tooltip>

        <inlog-dialog  v-if="!$store.state.module_login.info_login"/>
	</v-app-bar>
</template>

<script>
import InlogDialog from "@/components/drawers_dialogs_bars/InlogDialog";

export default {
	//name: "HomeBase",

    components: {
		InlogDialog,
	},

	data: () => ({
        items: [],
        itemsAll: [
			{   title: "Home",  icon:"mdi-home",      to: "/",    sub_items: null },
        ],
		itemsAdm: [
            { 
                title: "Tests", icon:"mdi-test-tube", to: null,   sub_items: [
                    { title: "About", icon:"mdi-information", to: "/about", sub_items: null },
                    { title: "Users", icon:"mdi-account-multiple-check", to: "/users", sub_items: null },
                    { title: "Dragdrop", icon:"mdi-drag-variant", to: "/dragdrop", sub_items: null },
                    { title: "Dragdrop2", icon:"mdi-drag-variant", to: "/dragdrop2", sub_items: null },
                    { title: "Admin app",   icon:"mdi-shield-crown-outline", to: "/Adm", sub_items: null },
                ]
            },
			{ title: "bieren", icon:"mdi-glass-mug-variant", to: null, sub_items: [
                    { title: "Bier", icon:"mdi-glass-mug-variant", to: "/Beer", sub_items: null },
                    { title: "Bier base",  icon:"mdi-glass-mug-variant", to: "/beer_base", sub_items: null },
                ]
            },
		],
	}),

    created() {
        this.items = this.itemsAll;
        if(this.$store.state.module_login.info_login) {
            if(this.$store.state.module_login.info_login.rol == 'adm') {
                this.items = [...this.itemsAll, ...this.itemsAdm];
                console.log("admin is ingelogd: alle menuitems")
            } 
        }
    },

	methods: {
        handleLogout: function () {
            this.$root.$emit('showSnackbar', {text: 'Gebruiker is uitgelogd', type: 'warning', time:7000});
            this.$store.commit('logout');
            //if (this.$route.path !== `/`) this.$router.push('/'); 
        },
	},
};
</script>

<style scoped> 
    #mainMenu { z-index:11} /* zodat ook Excel-headers achter menubalk verdwijnen */
</style>
