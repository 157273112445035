<template>
	<v-navigation-drawer
        v-model="left_drawer"
        absolute
        bottom
        permanent
        expand-on-hover
        v-bind:width="400"
    >
        <v-card class="" height="100%" color="">
            <v-list>
                <v-list-item class="px-2">
                    <v-list-item-avatar >
                        <v-icon @click.stop="left_drawer = !left_drawer" large class="mr-2">mdi-account-box-outline</v-icon>
                    </v-list-item-avatar>
                </v-list-item>

                <v-list-item 
                    v-for="person in aPersonFields" 
                    v-bind:key="person.field"
                >
                    <v-list-item-icon>
                        <v-icon color="indigo">{{person.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6">
                            <span v-if="person.field == 'startdate' || person.field == 'exdate' ">
                                {{ [$store.state.module_login.info_login[person.field], 'xsDT'] | formatDate }}
                            </span>
                            <span v-else>
                                {{$store.state.module_login.info_login[person.field]}}
                            </span>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{person.title}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                
            </v-list>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
    
    export default {
        name: "UserInfo",

        data: () => ({
            cookieInterval: null,
            left_drawer: true,
            aPersonFields: [
                { 
                    field:'naam',         
                    title:'naam', 
                    icon:'mdi-account-outline'},
                { 
                    field:'inaam',        
                    title:'inlognaam', 
                    icon:'mdi-account-check'
                },
                { 
                    field:'email',        
                    title:'email', 
                    icon:'mdi-email-outline'
                },
                { 
                    field:'rol',          
                    title:'rol', 
                    icon:'mdi-clipboard-account-outline'
                },
                { 
                    field:'startdate',
                    title:'start datum-tijd', 
                    icon:'mdi-account-clock-outline'
                },
                { 
                    field:'exdate',    
                    title:'verloop datum-tijd', 
                    icon:'mdi-clock-end'
                },
                { 
                    field:'headerBearer',    
                    title:'headerBearer', 
                    icon:'mdi-pound-box'
                },
            ]
        }),
    };
</script>