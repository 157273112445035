<template>
	<div class="text-center">
		<v-snackbar
			v-model="snackbar.visible"
			:color="snackbar.color"
			:multi-line="snackbar.mode === 'multi-line'"
			:timeout="snackbar.timeout" 
			:top="snackbar.position === 'top'"
            
		>
			<v-layout align-center pr-4>
				<v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
				<v-layout column>
					<div>
						<strong>{{ snackbar.title }}</strong>
					</div>
					<div>{{ snackbar.text }}</div>
                    
				</v-layout>
                <v-btn class="ml-6"
                    v-if="snackbar.timeout === -1"
                    icon
                    @click="snackbar.visible = false"
                >
                    <v-icon>mdi-close-box-outline</v-icon>
                </v-btn>
                
			</v-layout>

			
		</v-snackbar>
	</div>
</template>

<script>
export default {
	name: "SnackBar",

	// data: () => ({ kan ook }),
	data() {
		// https://codepen.io/scp-nm/pen/LYpBeoe
		return {
			snackbar: {              
                color: "",
                icon: "",
                mode: "",
                position: "",
                timeout: 7500,
                title: "",
                text: "",
                visible: null,
			},
		};
	},
	methods: {
		SnackbarShow(type, tekst, time) {
			if (!type) return;
            //console.log("SnackbarShow aangeroepen met type="+type+" en text: "+tekst+" time="+time);
           
			switch (type) {
				case "error":
					this.snackbar = {
						color: "error",
						icon: "mdi-close-octagon-outline",
						title: "Error",
                        visible: "true",
					};
					break;
				case "info":
					this.snackbar = {
						color: "info",
						icon: "mdi-information-outline",
						title: "Information",
                        visible: "true",
					};
					break;
				case "success":
					this.snackbar = {
						color: "success",
						icon: "mdi-check-circle-outline",
						title: "Success",
                        visible: "true",
					};
					break;
				case "warning":
					this.snackbar = {
						color: "warning",
						icon: "mdi-alert-outline",
						title: "Warning",
                        visible: "true",
					};
					break;
                
			}
            this.snackbar.text = tekst;
            this.snackbar.timeout = time;
            this.snackbar.mode = "multi-line";
			this.snackbar.position = "bottom";
		},
	},
    mounted() {  
        this.$root.$on('showSnackbar', (settings) => {
            //console.log("method van snackbar.vue aangeroepen van elders. Settings:",  settings);
            this.SnackbarShow(settings.type, settings.text, settings.time)
        })
	},
};
</script>