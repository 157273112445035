<template>
	<div>
		<h1>home</h1>
        <hr>
		<b>info_login vanuit de store </b>=
		{{ $store.state.module_login.info_login }}<br />
		<hr />
        
	</div>
</template>

<script>

export default {
	//name: "HomeBase",	
};
</script>
