<template>
	<div class="text-center">
		<v-dialog v-model="dialogInlog" width="500">
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" class="primary">
					<v-tooltip left nudge-left="15">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<v-icon class="mr-2">mdi-account-arrow-right</v-icon>
							</div>
						</template>
						<div>inloggen</div>
					</v-tooltip>
				</v-btn>
			</template>
			<v-card class="mx-auto">
				<v-card-title class="text-h5 pb-4 primary">
					<h2 style="color: white">Login</h2>
				</v-card-title>
				<v-card-text class="mt-6">
					<v-form>
						<v-text-field
							label="Username"
							prepend-icon="mdi-account-circle"
							v-model="inaam"
						/>
						<v-text-field
							:type="showPassword ? 'text' : 'password'"
							label="Password"
							v-model="pw"
							prepend-icon="mdi-lock"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="showPassword = !showPassword"
						/>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="info" @click="handleLogin">Login</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="message" @click="clearLogin">clear</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
//import axios from "axios";
import { getUser} from "@/api/user.js";

export default {
	name: "Inlog",

	data() {
		return {
			dialogInlog: false,
			showPassword: false,

			inaam: "cjs",
			pw: "",

			info_login: null,
		};
	},

	created: function () {
		// haal cookie op en zet in $store
		const name = "info_login_cookie";
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		let ret = null;
		if (parts.length === 2) {
			ret = parts.pop().split(";").shift();
			ret = JSON.parse(ret);
		}
        if(ret) {
            console.log("Vanuit Inlog.vue: Cookie bestaat en data is ingevuld en in store opgeslagen via $store.commit('save_login', ret) ");
            //this.$store.state.module_login.inlog_data = ret;
            this.$store.commit("save_login", ret);
        }
        
        //axios.defaults.headers.common['Authorization'] = `Bearer ${this.$store.state.module_login.headerBearer}`;  // staat ook al in store
        //axios.defaults.baseURL = 'http://localhost/stagion/bpv/api_bpv_v.php?action='
	},
	methods: {
		handleLogin: function () {
			let self = this;
            //console.log("Vanuit Inlog.vue: self.$store.state.module_login.headerBearer = "+self.$store.state.module_login.headerBearer);

            getUser(this.inaam, this.pw).then((apiData) => {
                console.log("getUsers() apiData:", apiData);
                if (apiData.data.bSuccess) {
                        //this.bLoaded = true;
                        //let response = apiData;

                        let l = apiData.data.data.length;
                        if (l==1) {
                            let infoLogin = apiData.data.data[0];
                            console.log("inlog juist! this.info_login = ", infoLogin);
                            self.$store.commit("save_login", infoLogin);
                            this.$root.$emit('showSnackbar', {text: self.$store.state.module_login.info_login.naam +" is succevol ingelogd", type: 'success', time:7000});
                        } else {
                            console.log("in inlog maar geen user gevonden met die credentials");
                            console.log("apiData.data.data[0] = ", apiData.data.data[0]);
                            //self.$store.commit("logout");
                            this.$root.$emit('showSnackbar', {text: 'Inlognaam komt niet overeen met wachtwoord', type: 'error'})
                        }
                    } else {
                        this.$root.$emit("showSnackbar", {
                            text: apiData.sMessage,
                            type: "error",
                            time: 7000,
                        });
                    }
                return null //response.data.data
            });

            // const href = window.location.href;
            // let baseUrlAction = null;
            // if (href.includes("808") || href.includes("localhost")) {
            //     //console.log("URL bevat '808' of 'localhost'. URL is: " + href);
            //     baseUrlAction = "http://localhost/stagion/beer/api_beer.php?action=";
            // } else {
            //     baseUrlAction = "api/index.php?action=";
            // }
            // let url = baseUrlAction+"getUser";
            //console.log("this.inaam =" + this.inaam, "this.pw =" + this.pw)

            
			
            // axios
            // //axios.defaults. headers.common['Authorization'] = `Bearer ${$store.state.info_login.headerBearer}`;
			// 	//.get(url, { headers: {"Authorization" : `Bearer ${$store.state.info_login.headerBearer}`} })
            //     //.get(url)
            //     .post(url, { inaam: this.inaam, ww: this.pw })
			// 	.then((response) => {
			// 		console.log("in inlog, response", response);
                    
            //         let l = response.data.data.length;
			// 		if (l==1) {
			// 			let infoLogin = response.data.data[0];
			// 			//console.log("inlog juist! this.info_login = ", infoLogin); // log KOMT OOK VANUIT STORE
			// 			self.$store.commit("save_login", infoLogin);
            //             this.$root.$emit('showSnackbar', {text: self.$store.state.module_login.info_login.naam +" is succevol ingelogd", type: 'success', time:7000});
			// 		} else {
            //             console.log("in inlog maar geen user gevonden met die credentials");
			// 			//self.$store.commit("logout");
            //             this.$root.$emit('showSnackbar', {text: 'Inlognaam komt niet overeen met wachtwoord', type: 'error'})
			// 		}
			// 	})
			// 	.catch(function (error) {
			// 		console.log(error);
			// 	});
			this.clearLogin();
			this.dialogInlog = false;
		},

		clearLogin: function () {
			this.pw = "";
			this.inaam = "";
		},
	},
};
</script>
